import { render, staticRenderFns } from "./Flat.vue?vue&type=template&id=2f8574a6&scoped=true"
import script from "./Flat.vue?vue&type=script&lang=js"
export * from "./Flat.vue?vue&type=script&lang=js"
import style0 from "./Flat.vue?vue&type=style&index=0&id=2f8574a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6.0_webpack@4.47.0__lodash_sniwsisxdvcgzet3yrvlfqnueq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f8574a6",
  null
  
)

export default component.exports