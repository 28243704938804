<template>
  <SinglePage>
    <div class="grid-x align-center">
      <div class="cell small-12 medium-7">
        <h1>Vill du jobba med oss?</h1>

        <p>Vi är ett litet men växande företag som hjälper människor att byta bostad. Vi har funnits i ca 10 år och tusentals svenskar har använt våra tjänster för att byta bostad.</p>
        <p>Just nu växer vi och söker bland annat medarbetare till vårt kontor i Stockholm:</p>
        <ul>
          <li>Kundtjänstmedarbetare</li>
          <li>Social Media och content manager</li>
          <li>Utvecklare (JS)</li>
        </ul>
        <p>Skicka ett mail till jobb@bytbostad.se om du är intresserad av att jobba med oss!</p>
      </div>
    </div>
  </SinglePage>
</template>

<script>
import SinglePage from '@/components/SinglePage.vue'
export default {
  components: {
    SinglePage
  }
}
</script>
