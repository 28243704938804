var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{},[_c('NavBar'),_c('router-view'),(
        [
          'home',
          'faq',
          'login',
          'ad',
          'my-account',
          'matches',
          'create-ad',
          'about-us',
          'jobs',
          'terms',
          'history',
          'lagenhetsbyte'
        ].includes(_vm.$route.name)
      )?_c('Footer'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }