var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{attrs:{"id":"page-wrap"}},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-x grid-margin-x"},[_c('div',{staticClass:"cell small-12 medium-4"},[_c('div',{staticClass:"sticky-container faq-sidebar",attrs:{"sticky-container":""}},[_c('div',{directives:[{name:"sticky",rawName:"v-sticky"}],staticClass:"sticky-inner",attrs:{"sticky-offset":"offset","sticky-side":"top"}},[_vm._m(0)])])]),_c('div',{staticClass:"cell small-12 medium-8"},[_c('section',{attrs:{"id":"qa-wrapper"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('article',{staticClass:"qa-item"},[_vm._m(5),_c('p',[_vm._v(" Gå till "),_c('router-link',{attrs:{"to":{ name: 'history'}}},[_vm._v(" historik ")]),_vm._v(" för att se alla annonser du sagt ja eller nej till. ")],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"sidebar-wrap"},[_c('div',{staticClass:"sidebar-content"},[_c('header',[_c('h1',[_vm._v("Epost")]),_c('h2',[_c('a',{attrs:{"href":"mailto:info@bytbostad.se","target":"_blank"}},[_vm._v("info@bytbostad.se")])]),_c('h1',[_vm._v("Facebook")]),_c('h2',[_c('a',{attrs:{"href":"https://fb.com/bytbostad","target":"_blank"}},[_vm._v("@bytbostad")])]),_c('h1',[_vm._v("Messenger")]),_c('h2',[_c('a',{attrs:{"href":"https://fb.me/bytbostad","target":"_blank"}},[_vm._v("fb.me/bytbostad")])]),_c('h1',[_vm._v("Twitter")]),_c('h2',[_c('a',{attrs:{"href":"https://twitter.com/bytbostad","target":"_blank"}},[_vm._v("@bytbostad")])]),_c('h1',[_vm._v("Instagram")]),_c('h2',[_c('a',{attrs:{"href":"https://instagram.com/bytbostad","target":"_blank"}},[_vm._v("@bytbostad")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"qa-item"},[_c('header',[_c('h2',[_vm._v("Hur funkar detta?")])]),_c('p',[_vm._v("På "),_c('strong',[_vm._v("BytBostad")]),_vm._v(" kan du som har en bostad lägga upp den för byte. Du får sedan tacka ja eller nej till olika bostäder som matchar vad du söker.")]),_c('p',[_vm._v("När du har en matching för ett direktbyte eller ett triangelbyte så får alla deltagarna i bytet ta del av varandras kontaktuppgifter och kan påbörja ett lägenhetsbyte.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"qa-item"},[_c('header',[_c('h2',[_vm._v("Vad kostar det?")])]),_c('p',[_vm._v("Det är gratis att lägga in sin annons, men för att kunna anmäla intresse på andra bostäder måste du ha ett aktivt medlemskap. Medlemskapet kostar 129 kronor i månaden.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"qa-item"},[_c('header',[_c('h2',[_vm._v("Är det garanterat att jag hittar ett byte?")])]),_c('p',[_vm._v("Vi kan inte garantera ett byte men vi jobbar aktivt på att få in objekt där våra medlemmar söker. Du kan öka chansen att hitta ett byte genom att markera intresse (eller ointresse) för alla bostäder du presenteras för.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"qa-item"},[_c('header',[_c('h2',[_vm._v("Kan jag radera mitt konto?")])]),_c('p',[_vm._v("Självklart kan du radera ditt konto och alla personuppgifter, du gör detta genom att logga in på sajten, välja \"Mitt konto\" och gå längst ner på sidan, där kan du välja att radera dina uppgifter.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Hur ser jag annonser jag sagt JA eller NEJ till?")])])
}]

export { render, staticRenderFns }