import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Ad from './views/Ad.vue'
import AdList from './views/AdList.vue'
import AdListGrid from './views/AdListGrid.vue'
import AdCreate from './views/AdCreate.vue'
import MyFilters from './components/MyFilters.vue'
import MyAds from './views/MyAds.vue'
import Login from './views/Login.vue'
import NotFound from './views/404.vue'
import MyAccount from './views/MyAccount.vue'
import Matches from './views/Matches.vue'
import FAQ from './views/FAQ.vue'
import AdPhotos from './views/AdPhotos.vue'
import AdForm from '@/components/AdForm.vue'
// import Checkout from '@/views/Checkout'
import ResetPw from '@/views/ResetPw'
import Admin from '@/views/Admin'
import store from '@/store'
import AboutUs from './views/AboutUs.vue'
import WorkHere from './views/WorkHere.vue'
import Terms from './views/Terms.vue'
import History from './views/History.vue'
import DeleteData from './views/DeleteData.vue'
import Lagenhetsbyte from './views/Lagenhetsbyte.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
      
    },
    {
      path: '/historik',
      name: 'history',
      component: History
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue')
    },
    {
      name: 'ad',
      path: '/bostad/:id',
      component: Ad,
      props: true
    },
    {
      name: 'adlist',
      path: '/annonser',
      component: AdList,
      beforeEnter: (to, from, next) => {
        if (!store.getters.user) {
          next({ name: 'create-ad' })
        } else if (store.getters.myAds.length > 1) {
          next({ name: 'my-ads' })
        } else {
          next()
        }
      }
    },
    {
      name: 'adlist-grid',
      path: '/annonser-grid',
      component: AdListGrid,
      beforeEnter: (to, from, next) => {
        if (store.getters.myAds.length > 1) {
          next({ name: 'my-ads' })
        } else {
          next()
        }
      }
    },
    {
      name: 'my-ads',
      path: '/mina-annonser',
      component: MyAds
    },
    {
      name: 'terms',
      path: '/villkor',
      component: Terms
    },
    {
      name: 'jobs',
      path: '/jobb',
      component: WorkHere
    },
    {
      name: 'about-us',
      path: '/om-oss',
      component: AboutUs
    },
    {
      name: 'create-ad',
      path: '/skapa-annons',
      component: AdCreate
    },
    {
      name: 'admin',
      path: '/admin',
      component: Admin
    },
    {
      name: 'my-filters',
      path: '/filter',
      component: MyFilters
    },
    {
      name: 'resetpw',
      path: '/nytt-losenord/:uuid',
      component: ResetPw,
      props: true
    },
    {
      name: 'login',
      path: '/login',
      component: Login
    },
    {
      name: 'my-account',
      path: '/mitt-konto',
      component: MyAccount
    },
    {
      name: 'delete-data',
      path: '/radera-data',
      component: DeleteData
    },
    {
      name: 'lagenhetsbyte',
      path: '/lagenhetsbyte',
      component: Lagenhetsbyte
    },
    {
      name: 'checkout',
      path: '/betala',
      component: () => import('./views/Checkout.vue')
    },
    {
      name: 'thanks',
      path: '/klart',
      component: () => import('./views/Thanks.vue')
    },
    {
      name: '360-viewing',
      path: '/360-visning',
      component: () => import('./views/360-viewing.vue')
    },
    {
      name: 'matches',
      path: '/matchningar',
      component: Matches,
      beforeEnter: (to, from, next) => {
        if (store.getters.myAds.length > 1) {
          next({ name: 'my-ads' })
        } else {
          next()
        }
      }
    },
    {
      name: 'newad',
      path: '/newad',
      component: AdForm
    },
    {
      name: 'faq',
      path: '/faq',
      component: FAQ
    },
    {
      name: 'ad-photos',
      path: '/bostad/:adid/:flatid/bilder',
      component: AdPhotos,
      props: true
    },
    {
      name: '404',
      path: '/404',
      component: NotFound
    },
    { path: '*', redirect: '/404' }
  ]
})
