<template>
  <div class="cell small-12 medium-8">
    <div class="content-wrap">
      <!-- Card img -->
      <div @click="$emit('closeModal')">

        <!-- <div v-if="flat.matterport">
          <iframe width="100%" height="480" class="ad-featured-img" :src="'https://my.matterport.com/show/?m=' +  flat.matterport" frameborder="0" allowfullscreen allow="xr-spatial-tracking"></iframe>
           <router-link :to="{ name: '360-viewing' }"  class="try-360">360-visning från BytBostad</router-link>
        </div> -->

        <PhotoGrid

          v-if="flat.photos"
          :key="flat.id"
          :flatid="flat.id"
          :photos="flat.photos.slice(0, 1)"
          :featured="true"
          :mine="flat.mine"
          @click="$emit('closeModal')" />
        <div v-if="!flat.photos.length">
          <!-- <h1>Inga bilder</h1> -->

          <div class="ad-featured-img">
            <div class="noimages">
              <button v-if="ad.mine" class="button-large secondary small" @click.prevent="editPhotos">
                Ladda upp bilder
              </button>
            </div>
            <!-- <img src="/img/bild_saknas.jpg" alt="Bilder saknas"> -->
          </div>
        </div>
      </div>
      <!-- TODO: If there are no photos -->

      <!-- Description -->
      <div class="ad-description">
        <div class="grid-x">
          <div class="cell" :class="{'small-12': !ad.mine, 'small-10':ad.mine}">
            <h3>Beskrivning</h3>
          </div>
          <!-- TODO: style button -->
          <div v-if="ad.mine" class="cell small-2">
            <button v-if="!edit" class="button-frameless" @click="editFlat">
              Redigera
            </button>
          </div>
        </div>

        <template v-if="ad.mine && edit">
          <form @submit.prevent="updateFlat">
            <div class="grid-x grid-margin-x">
              <div class="cell small-12">
                <EditorContent
                  v-model="newDescription"
                  :editor="editor"
                  placeholder="Beskriv bostaden" />
              </div><div class="cell small-12">
                <FloatLabel>
                  <input v-model="newFlat.landlord"
                         type="text" placeholder="Hyresvärd">
                </FloatLabel>
              </div>

              <div class="cell small-6">
                <button class="button-large gray" @click.prevent="edit = false">
                  Avbryt
                </button>
              </div>
              <div class="cell small-6">
                <button class="button-large secondary" :disabled="newFlat === flat">
                  Spara
                </button>
              </div>
            </div>
          </form>
        </template>

        <template v-if="!edit">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="flat.description"></div>
        </template>
      </div>

      <PhotoGrid v-if="flat.photos" :key="flat.id" :flatid="flat.id"
                 :photos="flat.photos.slice( flat.matterport ? 0 : 1)" />

      <!-- Map -->
      <template>
        <LMap
          v-if="showMap"
          class="ad-map"
          :zoom.sync="zoom"
          :options="mapOptions"
          :center="center"
          :min-zoom="minZoom"
          :max-zoom="maxZoom">
          <LTileLayer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base" />
          <LMarker :lat-lng="center" :icon="icon" />
        </LMap>
      </template>
      <!-- Map : END -->
    </div>
    <!-- content-wrap : END -->
  </div>
</template>

<script>
import PhotoGrid from '@/components/PhotoGrid.vue'
import { Editor, EditorContent } from 'tiptap'
import FloatLabel from '@/components/FloatLabels.vue'
import AdService from '@/services/AdService.js'
const clonedeep = require('lodash.clonedeep')

// import _ from 'loadash'
export default {
  components: {
    PhotoGrid,
    EditorContent,
    FloatLabel,

  },
  props: {
    ad: {
      type: Object,
      required: true
    },
    adid: {
      type: String,
      required: true
    },
    flat: {
      type: Object,
      required: true
    },
    showMap: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      newFlat: {},
      newDescription: '',
      edit: false,
      editor: null,
      // editor: InlineEditor,
      // editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // removePlugins: 'toolbar',

        // The configuration of the editor.
        toolbar: [],
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Brödtext',
              class: 'ck-heading_paragraph'
            }
            // {
            //   model: 'heading3',
            //   view: 'h3',
            //   title: 'Rubrik',
            //   class: 'ck-heading_heading3'
            // }
          ]
        }
      },
    }
  },
  methods: {
    updateFlat() {
      const updateFlat = clonedeep(this.newFlat)
      // console.log(updateFlat)

      AdService.updateFlat(this.flat.id, updateFlat).then(() => {
        // console.log('Success')

        // this.$store.dispatch('getMyAds').then(()=>{
        this.$emit('updateflat')
        this.edit = false

        // })
        // })
      }).catch(err => {
       Sentry.captureException(err);

      })
    },
    editFlat() {
      // Copy this.flat to this.newFlat to edit the copy before submitting
      // this.newFlat = Object.assign({}, this.flat)
      this.newFlat = { ...this.flat }
      // Turn on the editor
      this.edit = true
      this.editor = new Editor({
        content: this.newFlat.description,
        onUpdate: ({ getHTML }) => {
          // this.json = getJSON()
          this.newFlat.description = getHTML()
        }
      })
    },
    editPhotos() {
      this.$router.push({ name: 'ad-photos', params: { adid: this.adid, flatid: this.flat.id } })
    },
  }
}
</script>

<style scoped>
button.gray{
  color: #666666;
  background-color: #d8d8d8;
}
.noimages{
  background: url('/img/bild_saknas.jpg');
  height:300px;
  background-size:cover;
  background-position: center center;
display: flex;
  justify-content: center;
  align-items: center;
}
.small{
  max-width:200px;
}

/* TODO: Make this a global style */
button[disabled]{
     opacity: 0.4;
    cursor: not-allowed !important;
}

.try-360{
  color:#ABABAB;
  text-decoration: none;
    display: grid;
    justify-content: right;
    gap: 20px;
    padding: 10px;
}
</style>
